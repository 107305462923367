import React, { useState, useEffect } from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Instagram from '../assets/images/icons-insta-white.svg';
import Facebook from '../assets/images/facebook-logo-white.svg';
import linkedin from '../assets/images/icons-linkedin-white.svg';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import Flickity from 'react-flickity-component'

const flickityOptions = {
  cellAlign: 'center',
  wrapAround: true,
  pageDots: false,
  imagesLoaded: false,
  contain: true,
  autoPlay: 2000,
  arrowShape: 'M51.7,97.7l4.4-4.4c1-1,1-2.7,0-3.7L21.9,55.5H97c1.5,0,2.6-1.2,2.6-2.6v-6.2c0-1.5-1.2-2.6-2.6-2.6H21.9 L56,9.9c1-1,1-2.7,0-3.7l-4.4-4.4c-1-1-2.7-1-3.7,0L1.9,47.9c-1,1-1,2.7,0,3.7l46.1,46.1C49,98.7,50.7,98.7,51.7,97.7z'
}

const NotFoundPage: React.FC = () => {

  const { t } = useTranslation();

  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    //  Enable Flickity
    setTimeout(function () { setPageLoaded(true); }, 500);
  }, []);

  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="body-wrap">

        <div className="social-share">

          <div className="container">

            <div className="row text-end">

              <ul className="social">
                <li>
                  <a href="#" target="_blank" >
                    <img src={Instagram} alt="" />
                  </a>
                </li>
                <li><a href="#" target="_blank"><img src={Facebook} alt="" /></a></li>
                <li><a href="#" target="_blank"><img src={linkedin} alt="" /></a></li>
              </ul>
              <p>follow us on social media</p>

            </div>

          </div>

        </div>

        <section className="content">

          <div className="container">

            <div className="row">

              <h1>404</h1>
              <h2 className="text-center">Your page could not be found.</h2>

            </div>

          </div>

        </section>

      </div>
    </Layout>
  )

}


export default NotFoundPage;
